<!--
#########################################################################################
########    Vista SVC51200  :  CONSULTA ACTIVIDAD ALMACEN                        ########
#########################################################################################
-->
<template>
    <div>
        <!-- Top Navigation (Genérico) -->
        <TNC10000></TNC10000>
  
        <!-- Transición -->
        <div class="igs-back-color8 igs-alto2">&nbsp;</div>

        <!-- Artículo, Operario, .... -->
        <div class="row igs-back-color8">
            <div class="col-md-1 igs-l5 igs-center igs-l-pad-20">
              <b>Desde</b><br/>
              <div class="igs-mt-5"><b>Hasta</b></div>
            </div>
            <div class="col-md-2 igs-l1 igs-left">
              <!-- Almacenero&nbsp;&nbsp;&nbsp;&nbsp; -->
              <!-- <input type="text" v-model="ltFiltroDesdeOperario" maxlength="6" size="8" class="igs-l3" /> -->
              <Multiselect class="igs-l2" style="width:98%" v-model="loFiltroDesdeOperario" :options="$store.state.strListaOperarios" :taggable="false" :multiple="false"
                              :custom-label="lf91DescripcionDesdeOperario"  track-by="o001"  @open="lf60ListaAbierta"  @close="lf60ListaCerrada"
                              placeholder="Almacenero Inicial" deselectLabel=" " selectLabel=" " selectedLabel=" " required="false">
                              <span class="igs-l2" slot="noResult">Inicio</span>
              </Multiselect>

              <br/>
              <!-- Almacenero&nbsp;&nbsp;&nbsp;&nbsp; -->
              <!-- <input type="text" v-model="ltFiltroHastaOperario" maxlength="6" size="8" class="igs-l3 igs-mt-5" /> -->
              <Multiselect class="igs-l2" style="width:98%" v-model="loFiltroHastaOperario" :options="$store.state.strListaOperarios" :taggable="false" :multiple="false"
                              :custom-label="lf91DescripcionHastaOperario"  track-by="o001"  @open="lf60ListaAbierta"  @close="lf60ListaCerrada"
                              placeholder="Almacenero Final" deselectLabel=" " selectLabel=" " selectedLabel=" " required="false">
                              <span class="igs-l2" slot="noResult">Final</span>
              </Multiselect>

            </div>

            <div class="col-md-2 igs-l4 igs-left">
              Dispositivo&nbsp;
              <input type="text" v-model="ltFiltroDesdeDispositivo" maxlength="6" size="8" class="igs-l3" />
              <br/>
              Dispositivo&nbsp;
              <input type="text" v-model="ltFiltroHastaDispositivo" maxlength="6" size="8" class="igs-l3 igs-mt-5" />
            </div>

            <div class="col-md-1">&nbsp;</div>

            <div class="col-md-3 igs-l4 igs-center">
                <div class="igs-mt-5">Rango de Fechas</div>
                <date-range-picker
                              ref="dp_RangoFechasDetalleMovimientos"
                              opens="left"
                              :append-to-body=true
                              :single-date-picker=false
                              :locale-data="dp1_localeData"
                              minDate="2023-01-01" maxDate="2035-12-31"
                              :timePicker=false
                              :timePicker24Hour=true
                              :showWeekNumbers=true
                              :showDropdowns=true
                              :autoApply=true
                              :ranges="dp1_defaultRanges"
                              :linkedCalendars=false
                              control-container-class="igs-btn-dh-fecha"
                              v-model="dp1_dateRange"
                              @update="lp30RangoFechasDetalleMovimientos"
                              >
                          <template v-slot:input="picker">
                            <div class="igs-color1 igs-l4 igs-mt-5">
                              <b>{{ picker.startDate | lf60FiltroFecha }} a {{ picker.endDate | lf60FiltroFecha }}</b>
                            </div>
                          </template>
                        </date-range-picker>
            </div>

            <div class="col-md-2 igs-l4 igs-left">
              <label class="checkbox">Filtros Adicionales (Artículo)</label>&nbsp;&nbsp;&nbsp;
              <input type="checkbox" v-model="lSWFiltrosAdicionalesArticulo" class="igs-l3 igs-mt-10">
            </div>

            <div class="col-md-1 igs-l6 igs-left">
                <span @click="lp98BusquedaDetalleMovimientos()">&nbsp;&nbsp;<Fa :icon="faSearch" /> Buscar</span>   
            </div>
        </div>

        <!-- Transición -->
        <div class="igs-back-color8 igs-alto3" v-if="lSWFiltrosAdicionalesArticulo">&nbsp;</div>

        <!-- Filtros Adicionales x Artículo -->
        <div class="row igs-back-color8" v-if="lSWFiltrosAdicionalesArticulo">
          <div class="col-md-1 igs-l5 igs-center igs-l-pad-20">
            <b>Desde</b><br/>
            <div class="igs-mt-5"><b>Hasta</b></div>
          </div>
          <div class="col-md-1 igs-l4 igs-left">
            &nbsp;Grupo&nbsp;
            <input type="text" v-model="ltFiltroDesdeTGP" maxlength="2" size="3" class="igs-l3" />&nbsp;
            <br/>
            &nbsp;Grupo&nbsp;
            <input type="text" v-model="ltFiltroHastaTGP" maxlength="2" size="3" class="igs-l3 igs-mt-5" />&nbsp;
          </div>
          <div class="col-md-2 igs-l4 igs-left">
            &nbsp;Fam. Estadística&nbsp;
            <input type="text" v-model="ltFiltroDesdeFES" maxlength="3" size="4" class="igs-l3" />&nbsp;
            <br/>
            &nbsp;Fam. Estadística&nbsp;
            <input type="text" v-model="ltFiltroHastaFES" maxlength="3" size="4" class="igs-l3 igs-mt-5" />&nbsp;
          </div>
          <div class="col-md-2 igs-l4 igs-left">
            &nbsp;Fam. Contable&nbsp;
            <input type="text" v-model="ltFiltroDesdeFCO" maxlength="3" size="4" class="igs-l3" />&nbsp;
            <br/>
            &nbsp;Fam. Contable&nbsp;
            <input type="text" v-model="ltFiltroHastaFCO" maxlength="3" size="4" class="igs-l3 igs-mt-5" />&nbsp;
          </div>
          <div class="col-md-2 igs-l4 igs-left">
            &nbsp;Proveedor&nbsp;
            <input type="text" v-model="ltFiltroDesdePRO" maxlength="6" size="7" class="igs-l3" />&nbsp;
            <br/>
            &nbsp;Proveedor&nbsp;
            <input type="text" v-model="ltFiltroHastaPRO" maxlength="6" size="7" class="igs-l3 igs-mt-5" />&nbsp;
          </div>
          <div class="col-md-2 igs-l4 igs-left">
            Fabricante&nbsp;
            <input type="text" v-model="ltFiltroDesdeFAB" maxlength="6" size="7" class="igs-l3" />&nbsp;
            <br/>
            Fabricante&nbsp;
            <input type="text" v-model="ltFiltroHastaFAB" maxlength="6" size="7" class="igs-l3 igs-mt-5" />&nbsp;
          </div>
          <div class="col-md-2 igs-l4 igs-left">
            &nbsp;Sección&nbsp;
            <input type="text" v-model="ltFiltroDesdeSCC" maxlength="6" size="7" class="igs-l3" />&nbsp;
            <br/>
            &nbsp;Sección&nbsp;
            <input type="text" v-model="ltFiltroHastaSCC" maxlength="6" size="7" class="igs-l3 igs-mt-5" />&nbsp;
          </div>
        </div>

        <!-- Transición -->
        <div class="igs-back-color8 igs-alto2">&nbsp;</div>

        <!-- Otros Filtros -->
        <div class="row igs-back-color8">
            <div class="col-md-3 igs-l4 igs-center igs-l-pad-20 igs-mt-10">
                <label class="checkbox">Incluir Movimientos NO Confirmados</label>&nbsp;&nbsp;&nbsp;
                <input type="checkbox" v-model="lSWMovimientosNoConfirmados">
            </div>

            <div class="col-md-2 igs-l4 igs-left">
              Tipo de Movimiento&nbsp;
              <select class="igs-select-sm igs-mt-10" v-model="ltFiltroTipoMovimiento">
                <option value="">- Indiferente -</option>
                <option value="10">Entrada Compras</option>
                <option value="19">Ubicación Entradas</option>
                <option value="20">Cargas</option>
                <option value="21">Retornos</option>
                <option value="2C">Venta Almacén</option>
                <option value="30">Traspasos Almacén</option>
                <option value="40">Recuento (Tarea)</option>
                <option value="41">Recuento (Libre)</option>
                <option value="60">Reubicación Retornos</option>
                <option value="65">Reubicación (Libre)</option>
              </select>            
            </div>

            <div class="col-md-1 igs-l4 igs-center">
              Zona de Carga&nbsp;
              <select class="igs-select-sm igs-mt-10" v-model="ltFiltroZonaCarga">
                <option value="">- Indiferente -</option>
                <option value="A">A</option>
                <option value="B">B</option>
                <option value="C">C</option>
                <option value="D">D</option>
                <option value="E">E</option>
                <option value="F">F</option>
                <option value="G">G</option>
                <option value="H">H</option>
                <option value="I">I</option>
                <option value="J">J</option>
                <option value="K">K</option>
                <option value="L">L</option>
                <option value="M">M</option>
                <option value="N">N</option>
                <option value="O">O</option>
                <option value="P">P</option>
                <option value="Q">Q</option>
                <option value="R">R</option>
                <option value="S">S</option>
                <option value="T">T</option>
                <option value="U">U</option>
                <option value="V">V</option>
                <option value="W">W</option>
                <option value="X">X</option>
                <option value="Y">Y</option>
                <option value="Z">Z</option>
              </select>            
            </div>

            <div class="col-md-3 igs-l4 igs-center">      
              Rango de Horas<br/>
              <select class="igs-select-sm igs-mt-10" v-model="ltDesdeHora">
                <option value="0000">00:00</option>
                <option value="0600">06:00</option>
                <option value="0615">06:15</option>
                <option value="0630">06:30</option>
                <option value="0645">06:45</option>
                <option value="0700">07:00</option>
                <option value="0715">07:15</option>
                <option value="0730">07:30</option>
                <option value="0745">07:45</option>                
                <option value="0800">08:00</option>
                <option value="0815">08:15</option>
                <option value="0830">08:30</option>
                <option value="0845">08:45</option>                
                <option value="0900">09:00</option>
                <option value="0915">09:15</option>
                <option value="0930">09:30</option>
                <option value="0945">09:45</option>                
                <option value="1000">10:00</option>
                <option value="1015">10:15</option>
                <option value="1030">10:30</option>
                <option value="1045">10:45</option>                
                <option value="1100">11:00</option>
                <option value="1115">11:15</option>
                <option value="1130">11:30</option>
                <option value="1145">11:45</option>                
                <option value="1200">12:00</option>
                <option value="1215">12:15</option>
                <option value="1230">12:30</option>
                <option value="1245">12:45</option>                
                <option value="1300">13:00</option>
                <option value="1315">13:15</option>
                <option value="1330">13:30</option>
                <option value="1345">13:45</option>                
                <option value="1400">14:00</option>
                <option value="1415">14:15</option>
                <option value="1430">14:30</option>
                <option value="1445">14:45</option>                
                <option value="1500">15:00</option>
                <option value="1515">15:15</option>
                <option value="1530">15:30</option>
                <option value="1545">15:45</option>                
                <option value="1600">16:00</option>
                <option value="1615">16:15</option>
                <option value="1630">16:30</option>
                <option value="1645">16:45</option>                
                <option value="1700">17:00</option>
                <option value="1715">17:15</option>
                <option value="1730">17:30</option>
                <option value="1745">17:45</option>                
                <option value="1800">18:00</option>
                <option value="1815">18:15</option>
                <option value="1830">18:30</option>
                <option value="1845">18:45</option>                
                <option value="1900">19:00</option>
                <option value="1915">19:15</option>
                <option value="1930">19:30</option>
                <option value="1945">19:45</option>                
                <option value="2000">20:00</option>
                <option value="2015">20:15</option>
                <option value="2030">20:30</option>
                <option value="2045">20:45</option>                
                <option value="2100">21:00</option>
                <option value="2115">21:15</option>
                <option value="2130">21:30</option>
                <option value="2145">21:45</option>                
                <option value="2200">22:00</option>
                <option value="2215">22:15</option>
                <option value="2230">22:30</option>
                <option value="2245">22:45</option>                
                <option value="2300">23:00</option>
                <option value="2315">23:15</option>
                <option value="2330">23:30</option>
                <option value="2345">23:45</option>                
                <option value="2359">23:59</option>
              </select>        
              &nbsp;&nbsp;
              <select class="igs-select-sm igs-mt-10" v-model="ltHastaHora">
                <option value="0000">00:00</option>
                <option value="0600">06:00</option>
                <option value="0615">06:15</option>
                <option value="0630">06:30</option>
                <option value="0645">06:45</option>
                <option value="0700">07:00</option>
                <option value="0715">07:15</option>
                <option value="0730">07:30</option>
                <option value="0745">07:45</option>                
                <option value="0800">08:00</option>
                <option value="0815">08:15</option>
                <option value="0830">08:30</option>
                <option value="0845">08:45</option>                
                <option value="0900">09:00</option>
                <option value="0915">09:15</option>
                <option value="0930">09:30</option>
                <option value="0945">09:45</option>                
                <option value="1000">10:00</option>
                <option value="1015">10:15</option>
                <option value="1030">10:30</option>
                <option value="1045">10:45</option>                
                <option value="1100">11:00</option>
                <option value="1115">11:15</option>
                <option value="1130">11:30</option>
                <option value="1145">11:45</option>                
                <option value="1200">12:00</option>
                <option value="1215">12:15</option>
                <option value="1230">12:30</option>
                <option value="1245">12:45</option>                
                <option value="1300">13:00</option>
                <option value="1315">13:15</option>
                <option value="1330">13:30</option>
                <option value="1345">13:45</option>                
                <option value="1400">14:00</option>
                <option value="1415">14:15</option>
                <option value="1430">14:30</option>
                <option value="1445">14:45</option>                
                <option value="1500">15:00</option>
                <option value="1515">15:15</option>
                <option value="1530">15:30</option>
                <option value="1545">15:45</option>                
                <option value="1600">16:00</option>
                <option value="1615">16:15</option>
                <option value="1630">16:30</option>
                <option value="1645">16:45</option>                
                <option value="1700">17:00</option>
                <option value="1715">17:15</option>
                <option value="1730">17:30</option>
                <option value="1745">17:45</option>                
                <option value="1800">18:00</option>
                <option value="1815">18:15</option>
                <option value="1830">18:30</option>
                <option value="1845">18:45</option>                
                <option value="1900">19:00</option>
                <option value="1915">19:15</option>
                <option value="1930">19:30</option>
                <option value="1945">19:45</option>                
                <option value="2000">20:00</option>
                <option value="2015">20:15</option>
                <option value="2030">20:30</option>
                <option value="2045">20:45</option>                
                <option value="2100">21:00</option>
                <option value="2115">21:15</option>
                <option value="2130">21:30</option>
                <option value="2145">21:45</option>                
                <option value="2200">22:00</option>
                <option value="2215">22:15</option>
                <option value="2230">22:30</option>
                <option value="2245">22:45</option>                
                <option value="2300">23:00</option>
                <option value="2315">23:15</option>
                <option value="2330">23:30</option>
                <option value="2345">23:45</option>                
                <option value="2359">23:59</option>
              </select>        
            </div>

            <div class="col-md-1 igs-l4 igs-center">
              <span @click="lp98ExportXLS()"  v-if="laRegistrosDMA.length > 0">&nbsp;&nbsp;<Fa :icon="faFileExcel" />&nbsp;Excel</span> <br/>
              <span v-if="laRegistrosDMA.length > 0" class="igs-l2 igs-color7">{{ laRegistrosDMA.length }} Movimientos</span>  
            </div>
        </div>

        <!-- Transición -->
        <div class="igs-back-color8 igs-alto2">&nbsp;</div>

        <!-- Resultados -->
        <div class="tableFixHead">

          <!-- HUD -->
          <div class="row">
            <div class="col-12 igs-ancho-100 igs-center" v-if="lbCargando">  
              <br/><br/>
              <h2 class="igs-color2">Buscando resultados ....</h2>
              <br/><br/>
              <vue-ellipse-progress :loading="true" />
            </div>
          </div>

          <!-- Encabezado CABECERA -->
          <table ref="Tabla512" v-if="!lbCargando">
            <thead>
              <tr class="igs-l3 igs-back-color2 igs-color0">
                <th scope="col" @click="lp51Sort(0)">Fecha<span  v-html="lf91ColumnaOrden(0)"></span></th>
                <th scope="col" @click="lp51Sort(1)">Almacenero<span  v-html="lf91ColumnaOrden(1)"></span></th>
                <th scope="col" @click="lp51Sort(2)" class="igs-back-color1 igs-right">Palés<span  v-html="lf91ColumnaOrden(2)" /></th>
                <th scope="col" @click="lp51Sort(3)" class="igs-back-color1 igs-right">Bultos<span  v-html="lf91ColumnaOrden(3)" /></th>
                <th scope="col" @click="lp51Sort(4)" class="igs-back-color1 igs-right">Magnitud<span  v-html="lf91ColumnaOrden(4)" /></th>
                <th scope="col" @click="lp51Sort(5)" class="igs-back-color1 igs-right">Kilos<span  v-html="lf91ColumnaOrden(5)" /></th>
                <th scope="col" @click="lp51Sort(6)" class="igs-back-color1 igs-right">Ref.<span  v-html="lf91ColumnaOrden(6)" /></th>
                <th scope="col" @click="lp51Sort(7)">Actividad<span  v-html="lf91ColumnaOrden(7)" /></th>
                <th scope="col" @click="lp51Sort(8)">Pausas<span  v-html="lf91ColumnaOrden(8)" /></th>
                <th scope="col" @click="lp51Sort(9)" class="igs-back-color7 igs-l2">Palé<span  v-html="lf91ColumnaOrden(9)" /></th>
                <th scope="col" @click="lp51Sort(10)" class="igs-back-color7 igs-l2">Bulto<span  v-html="lf91ColumnaOrden(10)" /></th>
                <th scope="col" @click="lp51Sort(11)" class="igs-back-color7 igs-l2">Magnitud<span  v-html="lf91ColumnaOrden(11)" /></th>
                <th scope="col" @click="lp51Sort(12)" class="igs-back-color7 igs-l2">Kilo<span  v-html="lf91ColumnaOrden(12)" /></th>
                <th scope="col" @click="lp51Sort(13)" class="igs-back-color7 igs-l2">Ref.<span  v-html="lf91ColumnaOrden(13)" /></th>
                <th scope="col" @click="lp51Sort(14)">Datos Complementarios<span  v-html="lf91ColumnaOrden(14)" /></th>
              </tr>
            </thead>
            <tbody>
              <!-- Lista de Resultados (CABECERA) -->
              <template v-for="(dma, index) in laRegistrosDMA">
                <tr :key="index" class="igs-l4">
                  <td scope="row">
                    <nobr>
                      <span @click="lp88ExpandirContraer(index)">
                        <Fa :icon="faAngleDown" v-if="!dma.dsw1" />
                        <Fa :icon="faAngleUp"   v-if="dma.dsw1"  />
                      </span>
                      &nbsp;&nbsp;{{ f70AMD2fDMA(dma.d002) }}
                    </nobr></td>
                  <td class="igs-left igs-bold">{{ dma.d582 }}</td>
                  <td class="igs-right">{{ f91Entero(dma.d924) }}</td>
                  <td class="igs-right">{{ f91Entero(dma.d921) }}</td>
                  <td class="igs-right">{{ f91Entero(dma.d922) }}</td>
                  <td class="igs-right">{{ f91Entero(dma.d923) }}</td>
                  <td class="igs-right">{{ f91Entero(dma.d925) }}</td>
                  <td class="igs-bold">{{f70Segundos2fHMS(dma.d911)}}</td>
                  <td>{{f70Segundos2fHMS(dma.d912)}}</td>
                  <td class="igs-l3">{{f70Segundos2fHMS(dma.d911/dma.d924)}}</td>
                  <td class="igs-l3">{{f70Segundos2fHMS(dma.d911/dma.d921)}}</td>
                  <td class="igs-l3">{{f70Segundos2fHMS(dma.d911/dma.d922)}}</td>
                  <td class="igs-l3">{{f70Segundos2fHMS(dma.d911/dma.d923)}}</td>
                  <td class="igs-l3">{{f70Segundos2fHMS(dma.d911/dma.d925)}}</td>
                  <td class="igs-l3 igs-left">{{ f70HMS2fHMS(dma.d901) }} - {{ f70HMS2fHMS(dma.d902) }} (<b class="igs-l4">{{ f91Porcentaje(dma.d911/(dma.d911+dma.d912)*100) }}%</b>)</td>
                </tr>

                <!-- Resultados (DETALLE) -->
                <tr :key="index+100000" class="igs-back-color8" v-if="dma.dsw1">
                  <td colspan="15" class="igs-back-color8">
                    <table width="100%" border="0">
                      <thead>
                        <tr class="igs-l3 igs-back-color2 igs-color0">
                          <th scope="col" @click="lp52Sort(index,0)" class="igs-back-color7">Tipo<span  v-html="lf92ColumnaOrden(index,0)" /></th>
                          <th scope="col" @click="lp52Sort(index,1)" class="igs-back-color7">Clave<span  v-html="lf92ColumnaOrden(index,1)" /></th>
                          <th scope="col" @click="lp52Sort(index,2)" class="igs-back-color1 igs-right">Palés<span  v-html="lf92ColumnaOrden(index,2)" /></th>
                          <th scope="col" @click="lp52Sort(index,3)" class="igs-back-color1 igs-right">Bultos<span  v-html="lf92ColumnaOrden(index,3)" /></th>
                          <th scope="col" @click="lp52Sort(index,4)" class="igs-back-color1 igs-right">Magnitud<span  v-html="lf92ColumnaOrden(index,4)" /></th>
                          <th scope="col" @click="lp52Sort(index,5)" class="igs-back-color1 igs-right">Kilos<span  v-html="lf92ColumnaOrden(index,5)" /></th>
                          <th scope="col" @click="lp52Sort(index,4)" class="igs-back-color1 igs-right">Ref.<span  v-html="lf92ColumnaOrden(index,4)" /></th>
                          <th scope="col" @click="lp52Sort(index,6)" colspan="2">Actividad<span  v-html="lf92ColumnaOrden(index,6)" /></th>
                          <th scope="col" @click="lp52Sort(index,8)" class="igs-back-color7 igs-l2">Palé<span  v-html="lf92ColumnaOrden(index,8)" /></th>
                          <th scope="col" @click="lp52Sort(index,9)" class="igs-back-color7 igs-l2">Bulto<span  v-html="lf92ColumnaOrden(index,9)" /></th>
                          <th scope="col" @click="lp52Sort(index,10)" class="igs-back-color7 igs-l2">Magnitud<span  v-html="lf92ColumnaOrden(index,10)" /></th>
                          <th scope="col" @click="lp52Sort(index,11)" class="igs-back-color7 igs-l2">Kilo<span  v-html="lf92ColumnaOrden(index,11)" /></th>
                          <th scope="col" @click="lp52Sort(index,11)" class="igs-back-color7 igs-l2">Ref.<span  v-html="lf92ColumnaOrden(index,11)" /></th>
                          <th scope="col" @click="lp52Sort(index,12)">Datos Complementarios<span  v-html="lf92ColumnaOrden(index,12)" /></th>
                        </tr>
                      </thead>
                      <tbody>
                        <!-- Lista de Resultados (DETALLE) -->
                        <template v-for="(det, index2) in laRegistrosDMA[index].ddma">
                          <tr :key="index2" class="igs-l3">
                            <td>{{ f93DescripcionTipoMovimientoDMA(det.d011,'',false) }}</td>
                            <td>{{ f93DescripcionReferenciaTextoDMA(det.d011, det.d014, '', '')  }}</td>
                            <td class="igs-right">{{ f91Cantidad(det.d924) }}</td>
                            <td class="igs-right">{{ f91Cantidad(det.d921) }}</td>
                            <td class="igs-right">{{ f91Cantidad(det.d922) }}</td>
                            <td class="igs-right">{{ f91Cantidad(det.d923) }}</td>
                            <td class="igs-right">{{ f91Cantidad(det.d925) }}</td>
                            <td colspan="2" class="igs-bold">{{f70Segundos2fHMS(det.d911)}}</td>
                            <td class="igs-l2">{{f70Segundos2fHMS(det.d911/det.d924)}}</td>
                            <td class="igs-l2">{{f70Segundos2fHMS(det.d911/det.d921)}}</td>
                            <td class="igs-l2">{{f70Segundos2fHMS(det.d911/det.d922)}}</td>
                            <td class="igs-l2">{{f70Segundos2fHMS(det.d911/det.d923)}}</td>
                            <td class="igs-l2">{{f70Segundos2fHMS(det.d911/det.d925)}}</td>
                            <td class="igs-l2 igs-left"><span @click="lp90DetallesActividad(index, index2)" v-if="det.d011 == '20'"><Fa :icon="faInfoCircle" /></span>&nbsp;{{ f70HMS2fHMS(det.d901) }} - {{ f70HMS2fHMS(det.d902) }}</td>
                          </tr>
                          </template>
                      </tbody>
                    </table>
                  </td>
                </tr>    
              </template>
            </tbody>
          </table>        
        </div>

        <BNC10000></BNC10000>

      <!-- Ventana Modal .11. (Detalles de una ACTIVIDAD concreta) -->
      <VModal name="VMLeyenda11" height="80%" :minWidth=1024 width="90%" @before-close="lp99OcultandoLeyenda11()" :draggable="false" :adaptive="true" :resizable="true" >
        <div class="igs-back-color8" style="height:100%; overflow-y: auto;">
          <div class="row">
            <div class="col-md-11 igs-back-color1"><h5 class="igs-back-color1 igs-color6 igs-center">Detalles Actividad ({{ ltVML11_Rotulo }})</h5></div>
            <div class="col-md-1 igs-color0 igs-back-color1 igs-l3" @click="$modal.hide('VMLeyenda11')"><Fa :icon="faWindowClose" /></div>
          </div>

          <div class="row">
            <div class="col-md-8 igs-bold">
              {{ ltVML11_DetallesFila1A }}
            </div>
            <div class="col-md-4 igs-bold">
              {{ ltVML11_DetallesFila1B }}
            </div>
          </div>

          <div class="row igs-pad-4">
            <div class="col-md-12 igs-left igs-l4">
              <table width="100%" border="0">
                <thead>
                  <tr class="igs-center igs-color6 igs-l3">
                    <th>Artículo</th>
                    <th>Cantidad</th>
                    <th>Lote</th>
                    <th>Caducidad</th>
                    <th>Ubicación</th>
                    <th>Validador</th>
                    <th>Detalles</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(lot, index) in lat200m522">
                    <tr :key="index">
                      <td>{{lot.l506}}</td>
                      <td class="igs-center igs-bold">{{ f91Cantidad(lot.l060) }}</td>
                      <td class="igs-l2">{{ lot.l051 }}</td>
                      <td class="igs-l2"><nobr>{{ lf91FechaCaducidad(lot.l071) }}</nobr></td>
                      <td><span v-html="f93MaquetarUbicacion(lot.l081,5)" class="igs-l2"></span></td>
                      <td>{{ lot.l583 }}</td>
                      <td>
                        <table width="100%" border="0">
                            <tr class="igs-back-color7 igs-color0 igs-bold igs-center igs-l3">
                              <td>Cliente</td>
                              <td>Documento</td>
                              <td>Cantidad</td>
                              <td>Vendedor</td>
                            </tr>
                          <tbody>
                            <template v-for="(doc, index2) in lat200m522[index].ldoc">
                              <tr :key="index2" class="igs-l2">
                                <td class="igs-bold">{{ doc.d510 }}</td>
                                <td class="igs-center">({{ doc.d001 }}) {{ doc.d002 }}</td>
                                <td class="igs-center igs-l3 igs-bold">{{ f91Cantidad(doc.d013) }}</td>
                                <td>{{ doc.d627 }}</td>
                              </tr>
                            </template>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>

          <div class="row igs-pad-4">
            <div class="col-md-4 igs-left igs-l4">&nbsp;</div>
            <div class="col-md-8 igs-left igs-l4">&nbsp;</div>
          </div>

          <div class="row igs-pad-4">
            <div class="col-md-12 igs-center igs-l4"><button class="igs-btn-primary ml-2" style="width:140px" @click="$modal.hide('VMLeyenda11');">Cerrar</button></div>
          </div>

          <div class="row igs-pad-4">
            <div class="col-md-4 igs-left igs-l4">&nbsp;</div>
            <div class="col-md-8 igs-left igs-l4">&nbsp;</div>
          </div>

        </div>
      </VModal>

  
    </div>
  </template>
  
  <script>
  import igs_mixin from '@/igs-mixins/igs_mixin'
  import Multiselect  from 'vue-multiselect'

  import TNC10000  from '@/igs-navegacion/TNC10000'
  import BNC10000  from '@/igs-navegacion/BNC10000'
  
  import axios         from 'axios';
  import * as XLSX     from 'xlsx';
  
  import Fa from 'vue-fa'
  import { faSearch, faFileExcel, faSortUp, faSortDown, faAngleDown, faAngleUp, faInfoCircle, faWindowClose } from '@fortawesome/free-solid-svg-icons'

  //-- Rango de Fechas
  import DateRangePicker from 'vue2-daterange-picker'
  import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

  export default {
    mixins: [ igs_mixin ],
    components : { TNC10000, BNC10000, DateRangePicker, Fa, Multiselect },
  
    data() {
        //-- Valores Predeterminado
        let startDate = new Date();
        let endDate   = new Date();
        startDate.setDate(endDate.getDate() - 6);

        let today = new Date()
        today.setHours(0, 0, 0, 0)

        let yesterday = new Date()
        yesterday.setDate(today.getDate() - 1)
        yesterday.setHours(0, 0, 0, 0);

        var thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
        var thisMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0, 11, 59, 59, 999);

      return {
        //------------------------------------------------------------------------
        //-- Iconos
        //------------------------------------------------------------------------
        faSearch, faFileExcel, faSortUp, faSortDown, faAngleDown, faAngleUp, faInfoCircle, faWindowClose,

         //------------------------------------------------------------------------
         //-- Variables Locales
         //------------------------------------------------------------------------
         ltFiltroDesdeArticulo : '',
         ltFiltroHastaArticulo : '',
         ltDescripcionDesdeArticulo : '',
         ltDescripcionHastaArticulo : '',
         ltFiltroDesdeOperario : '',
         ltFiltroHastaOperario : 'zzzzzz',
         ltFiltroDesdeDispositivo : '',
         ltFiltroHastaDispositivo : 'zzzzzz',

         loFiltroDesdeOperario : {},
         loFiltroHastaOperario : {},

         ltFiltroDesdeTGP : '00',
         ltFiltroHastaTGP : '99',
         ltFiltroDesdeFES : '',
         ltFiltroHastaFES : 'zzz',
         ltFiltroDesdeFCO : '',
         ltFiltroHastaFCO : 'zzz',
         ltFiltroDesdePRO : '',
         ltFiltroHastaPRO : 'zzzzzz',
         ltFiltroDesdeFAB : '',
         ltFiltroHastaFAB : 'zzzzzz',
         ltFiltroDesdeSCC : '',
         ltFiltroHastaSCC : 'zzzzzz',

         
         ltFiltroTipoMovimiento : '20',
         ltFiltroZonaCarga : '',

         ltDesdeHora : '0000',
         ltHastaHora : '2359',

         lSWMovimientosNoConfirmados : true,
         lSWFiltrosAdicionalesArticulo : false,         

         ltFiltroDesdeFecha   : '00000000',      
         ltFiltroHastaFecha   : '99999999',

         dp1_dateRange: {startDate, endDate},
         dp1_localeData : {
            direction: 'ltr',
            format: 'dd-mm-yyyy',
            separator: ' a ',
            applyLabel: 'Confirmar',
            cancelLabel: 'Cancelar',
            weekLabel: 'W',
            customRangeLabel: 'Rango',
            daysOfWeek: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
            monthNames: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
            firstDay: 1
         },
         dp1_defaultRanges : {
            'Hoy': [today, today],
            'Ayer': [yesterday, yesterday],
            'Mes Actual': [thisMonthStart, thisMonthEnd],
            'Mes Anterior': [new Date(today.getFullYear(), today.getMonth() - 1, 1), new Date(today.getFullYear(), today.getMonth(), 0)],
            'Año Actual': [new Date(today.getFullYear(), 0, 1), new Date(today.getFullYear(), 11, 31)]
         },

         laRegistrosDMA : [],                     //-- Lista de Registros a mostrar
         lat200m522 : [],                         //-- Lista de Registros Tabla 200, Método 522

         lnColumnaOrdenCabecera : 0,              //-- Índice de la Columna por la cual Ordenar los Resultados (Cabecera)
         lnColumnaOrdenDetalle : 0,               //-- Índice de la Columna por la cual Ordenar los Resultados (Detalle)        

         lbOrdenAscendenteCabecera : true,        //-- Ordenar los Resultados de forma ASCENDENTE (S/N) (Cabecera)
         lbOrdenAscendenteDetalle : true,         //-- Ordenar los Resultados de forma ASCENDENTE (S/N) (Cabecera)

         lbCargando : false,                      //-- Cargando Datos (Sí/No)

         ltVML11_Rotulo : '',                     //-- Rótulo (Ventana Modal 11)
         ltVML11_DetallesFila1A : '',             //-- Detalles (Fila .1., Porción A) (Ventana Modal 11) 
         ltVML11_DetallesFila1B : ''              //-- Detalles (Fila .1., Porción B) (Ventana Modal 11) 

      }
                
  
    },
  
    /**************************************************************/
    /****************   Métodos / Procedimientos   ****************/
    /**************************************************************/
    methods: {

        //............................................................................................
        //-- Conversión (Date) en (String) de la Fecha Inicial y Final para el Detalle de Movimientos
        //............................................................................................
        lp30RangoFechasDetalleMovimientos() {
            //-- Extraer Fecha INICIAL
            let startDate    = this.dp1_dateRange.startDate;
            let fechaInicial = this.f92Date2DTZ(startDate).substr(0,10).replace(/-/g,'');

            let endDate      = this.dp1_dateRange.endDate;
            let fechaFinal   = this.f92Date2DTZ(endDate).substr(0,10).replace(/-/g,'');

            //-- Retornar el valor calculado
            //console.log(' fechaInicial = ('+fechaInicial+') // fechaFinal = ('+fechaFinal+')');

            this.ltFiltroDesdeFecha   = fechaInicial;
            this.ltFiltroHastaFecha   = fechaFinal;
        },

        //----------------------------------------------------------------------
        //-- Ordenar Resultados (por el ÍNDICE de la COLUMNA indicada)
        //----------------------------------------------------------------------
        lp51Sort(indice) {
          //console.log(' lp51Sort ('+indice+')');

          //-- Misma Columna --> Conmutar Orden Ascendente/Descendente
          if (indice == this.lnColumnaOrdenCabecera) {
            this.lbOrdenAscendenteCabecera  = !this.lbOrdenAscendenteCabecera;
          } else {
            //-- Actualizar Columna y Orden
            this.lnColumnaOrdenCabecera     = indice;
            this.lbOrdenAscendenteCabecera  = true;
          }

          //-- Ordenar Resultados
          this.lp60OrdenarResultados();

        },

        //---------------------------------------------------------------------------------
        //-- Al ABRIR la Lista de Operarios
        //---------------------------------------------------------------------------------
        lf60ListaAbierta() {
        },


        //---------------------------------------------------------------------------------
        //-- Al CERRAR la Lista de Operarios
        //---------------------------------------------------------------------------------
        lf60ListaCerrada() {
        },

        //--------------------------------------------------------------------------
        //-- Buscar y Mostrar Detalles de la Actividad de cierto Parcial (Detalle)
        //--------------------------------------------------------------------------
        lp60BuscarDetallesActividad(tipoActividad, claveActividad) {

          //-- Enviar Solicitud de Borrado al Servidor
          var URL = global.lnkServerAppURL + "&s="+this.$store.state.strIDSesion+"&u="+this.$store.state.strUsuario+"&d="+this.$store.state.strDistribuidor+"&a="+this.$store.state.strAlmacen+"&t=200&m=522&x=" + encodeURIComponent(tipoActividad+'^'+claveActividad);

          //-- Contactar con el Servidor del Distribuidor
          axios.get(URL, {responseEncoding: 'utf-8'})                         
          .then(respuesta => {

              //-- Extraer la respuesta recibida
              var datos = respuesta.data;

              //-- Determinar si ha habido EXITO ó ERROR
              var flg = global.lnkGetDataError;
              if (datos.flg) {  flg = datos.flg; }

              //-- Actualizar Resultados
              this.p99Log(1,1,'-- Respuesta (200522) --');
              this.p99Log(1,2,datos);

              //-- ¿ Exito ?
              if (flg == global.lnkGetDataOk)  {
                //-- Notificar EXITO sobre el LOG
                this.p99Log(1,2,' ¡¡ EXITO !!');

                //-- Cargar la LISTA de Resultados
                this.lat200m522 = datos.t200m522;

                //-- Mostrar la Ventana Modal Auxiliar
                this.$modal.show('VMLeyenda11');
              } else {
                if (datos.msg) { this.p99AnuncioIA1('2','7','ERROR', datos.msg,-1); } else { this.p99AnuncioIA1('2','7','ERROR', respuesta.data,-1); }
              }

          })
          .catch(error => {
              //-- Mostrar el Mensaje de Error oportuno
              this.p99Log(1,1,'** ERROR **');
              this.p99Log(1,1,error);

              //-- Mensaje al Operador
              this.p99AnuncioIA1('2','7','ERROR', error,-1);
          });

        },



        //----------------------------------------------------------------------
        //-- Ordenar los MOVIMIENTOS por el criterio seleccionado
        //----------------------------------------------------------------------
        lp60OrdenarResultados() {

            //-- Crear nueva lista con el Orden seleccionado
            let self = this;
            let listaOrdenada = this.laRegistrosDMA.sort(function(a, b){
              var nameA = '';
              var nameB = '';

              //-- Fecha y Hora Inicial
              if (self.lnColumnaOrdenCabecera == 0) {
                  nameA=a.d002+a.d082+a.d901;
                  nameB=b.d002+b.d082+b.d901;

                  if ((nameA < nameB) && self.lbOrdenAscendenteCabecera)  { return -1; }
                  if ((nameA > nameB) && self.lbOrdenAscendenteCabecera)  { return  1; }

                  if ((nameA < nameB) && !self.lbOrdenAscendenteCabecera) { return  1; }
                  if ((nameA > nameB) && !self.lbOrdenAscendenteCabecera) { return -1; }

                  return 0; 
              }

              //-- Almacenero + Fecha + Hora Inicial
              if (self.lnColumnaOrdenCabecera == 1) {
                  nameA=a.d082+a.d002+a.d901;
                  nameB=b.d082+b.d002+b.d901;

                  if ((nameA < nameB) && self.lbOrdenAscendenteCabecera)  { return -1; }
                  if ((nameA > nameB) && self.lbOrdenAscendenteCabecera)  { return  1; }

                  if ((nameA < nameB) && !self.lbOrdenAscendenteCabecera) { return  1; }
                  if ((nameA > nameB) && !self.lbOrdenAscendenteCabecera) { return -1; }

                  return 0; 
              }

              //-- Total Palés
              if (self.lnColumnaOrdenCabecera == 2) {
                  nameA=a.d924;
                  nameB=b.d924;

                  if ((nameA < nameB) && self.lbOrdenAscendenteCabecera)  { return -1; }
                  if ((nameA > nameB) && self.lbOrdenAscendenteCabecera)  { return  1; }

                  if ((nameA < nameB) && !self.lbOrdenAscendenteCabecera) { return  1; }
                  if ((nameA > nameB) && !self.lbOrdenAscendenteCabecera) { return -1; }

                  return 0; 
              }

              //-- Total Bultos
              if (self.lnColumnaOrdenCabecera == 3) {
                  nameA=a.d921;
                  nameB=b.d921;

                  if ((nameA < nameB) && self.lbOrdenAscendenteCabecera)  { return -1; }
                  if ((nameA > nameB) && self.lbOrdenAscendenteCabecera)  { return  1; }

                  if ((nameA < nameB) && !self.lbOrdenAscendenteCabecera) { return  1; }
                  if ((nameA > nameB) && !self.lbOrdenAscendenteCabecera) { return -1; }

                  return 0; 
              }

              //-- Total Coeficientes
              if (self.lnColumnaOrdenCabecera == 4) {
                  nameA=a.d922;
                  nameB=b.d922;

                  if ((nameA < nameB) && self.lbOrdenAscendenteCabecera)  { return -1; }
                  if ((nameA > nameB) && self.lbOrdenAscendenteCabecera)  { return  1; }

                  if ((nameA < nameB) && !self.lbOrdenAscendenteCabecera) { return  1; }
                  if ((nameA > nameB) && !self.lbOrdenAscendenteCabecera) { return -1; }

                  return 0; 
              }

              //-- Total Kilos
              if (self.lnColumnaOrdenCabecera == 5) {
                  nameA=a.d923;
                  nameB=b.d923;

                  if ((nameA < nameB) && self.lbOrdenAscendenteCabecera)  { return -1; }
                  if ((nameA > nameB) && self.lbOrdenAscendenteCabecera)  { return  1; }

                  if ((nameA < nameB) && !self.lbOrdenAscendenteCabecera) { return  1; }
                  if ((nameA > nameB) && !self.lbOrdenAscendenteCabecera) { return -1; }

                  return 0; 
              }

              //-- Referencias
              if (self.lnColumnaOrdenCabecera == 6) {
                  nameA=a.d925;
                  nameB=b.d925;

                  if ((nameA < nameB) && self.lbOrdenAscendenteCabecera)  { return -1; }
                  if ((nameA > nameB) && self.lbOrdenAscendenteCabecera)  { return  1; }

                  if ((nameA < nameB) && !self.lbOrdenAscendenteCabecera) { return  1; }
                  if ((nameA > nameB) && !self.lbOrdenAscendenteCabecera) { return -1; }

                  return 0; 
              }

              //-- Actividad
              if (self.lnColumnaOrdenCabecera == 7) {
                  nameA=a.d911;
                  nameB=b.d911;

                  if ((nameA < nameB) && self.lbOrdenAscendenteCabecera)  { return -1; }
                  if ((nameA > nameB) && self.lbOrdenAscendenteCabecera)  { return  1; }

                  if ((nameA < nameB) && !self.lbOrdenAscendenteCabecera) { return  1; }
                  if ((nameA > nameB) && !self.lbOrdenAscendenteCabecera) { return -1; }

                  return 0; 
              }

              //-- Pausa
              if (self.lnColumnaOrdenCabecera == 8) {
                  nameA=a.d912;
                  nameB=b.d912;

                  if ((nameA < nameB) && self.lbOrdenAscendenteCabecera)  { return -1; }
                  if ((nameA > nameB) && self.lbOrdenAscendenteCabecera)  { return  1; }

                  if ((nameA < nameB) && !self.lbOrdenAscendenteCabecera) { return  1; }
                  if ((nameA > nameB) && !self.lbOrdenAscendenteCabecera) { return -1; }

                  return 0; 
              }

              //-- Media Palés
              if (self.lnColumnaOrdenCabecera == 9) {
                  nameA=(a.d911/a.d924);
                  nameB=(b.d911/b.d924);

                  if ((nameA < nameB) && self.lbOrdenAscendenteCabecera)  { return -1; }
                  if ((nameA > nameB) && self.lbOrdenAscendenteCabecera)  { return  1; }

                  if ((nameA < nameB) && !self.lbOrdenAscendenteCabecera) { return  1; }
                  if ((nameA > nameB) && !self.lbOrdenAscendenteCabecera) { return -1; }

                  return 0; 
              }

              //-- Media Bultos
              if (self.lnColumnaOrdenCabecera == 10) {
                  nameA=(a.d911/a.d921);
                  nameB=(b.d911/b.d921);

                  if ((nameA < nameB) && self.lbOrdenAscendenteCabecera)  { return -1; }
                  if ((nameA > nameB) && self.lbOrdenAscendenteCabecera)  { return  1; }

                  if ((nameA < nameB) && !self.lbOrdenAscendenteCabecera) { return  1; }
                  if ((nameA > nameB) && !self.lbOrdenAscendenteCabecera) { return -1; }

                  return 0; 
              }

              //-- Media Coeficientes (Magnitudes)
              if (self.lnColumnaOrdenCabecera == 11) {
                  nameA=(a.d911/a.d922);
                  nameB=(b.d911/b.d922);

                  if ((nameA < nameB) && self.lbOrdenAscendenteCabecera)  { return -1; }
                  if ((nameA > nameB) && self.lbOrdenAscendenteCabecera)  { return  1; }

                  if ((nameA < nameB) && !self.lbOrdenAscendenteCabecera) { return  1; }
                  if ((nameA > nameB) && !self.lbOrdenAscendenteCabecera) { return -1; }

                  return 0; 
              }

              //-- Media Kilos
              if (self.lnColumnaOrdenCabecera == 12) {
                  nameA=(a.d911/a.d923);
                  nameB=(b.d911/b.d923);

                  if ((nameA < nameB) && self.lbOrdenAscendenteCabecera)  { return -1; }
                  if ((nameA > nameB) && self.lbOrdenAscendenteCabecera)  { return  1; }

                  if ((nameA < nameB) && !self.lbOrdenAscendenteCabecera) { return  1; }
                  if ((nameA > nameB) && !self.lbOrdenAscendenteCabecera) { return -1; }

                  return 0; 
              }

              //-- Media Referencias
              if (self.lnColumnaOrdenCabecera == 13) {
                  nameA=(a.d911/a.d925);
                  nameB=(b.d911/b.d925);

                  if ((nameA < nameB) && self.lbOrdenAscendenteCabecera)  { return -1; }
                  if ((nameA > nameB) && self.lbOrdenAscendenteCabecera)  { return  1; }

                  if ((nameA < nameB) && !self.lbOrdenAscendenteCabecera) { return  1; }
                  if ((nameA > nameB) && !self.lbOrdenAscendenteCabecera) { return -1; }

                  return 0; 
              }

              //-- % Actividad
              if (self.lnColumnaOrdenCabecera == 14) {
                  nameA=(a.d911/(a.d911+a.d912));
                  nameB=(b.d911/(b.d911+b.d912));

                  if ((nameA < nameB) && self.lbOrdenAscendenteCabecera)  { return -1; }
                  if ((nameA > nameB) && self.lbOrdenAscendenteCabecera)  { return  1; }

                  if ((nameA < nameB) && !self.lbOrdenAscendenteCabecera) { return  1; }
                  if ((nameA > nameB) && !self.lbOrdenAscendenteCabecera) { return -1; }

                  return 0; 
              }

              //-- Resto de Casos
              return 0;
            });

            //-- Reemplazar Lista de Resultados                                              
            this.laRegistrosDMA = listaOrdenada;

        },

        //----------------------------------------------------------------------
        //-- Conmutar Expandir/Contraer DETALLES del registro (indice)
        //----------------------------------------------------------------------
        lp88ExpandirContraer(indice) {
          this.laRegistrosDMA[indice].dsw1 = !this.laRegistrosDMA[indice].dsw1;
        },

        //-------------------------------------------------------------------------
        //-- Abrir Ventana Modal con DETALLES de la Actividad del Índice (indice)
        //-------------------------------------------------------------------------
        lp90DetallesActividad(indice1, indice2) {
          console.log(indice1+','+indice2);

          //-- Puntero a CABecera y DETalle
          let cab = this.laRegistrosDMA[indice1];
          let det = this.laRegistrosDMA[indice1].ddma[indice2];

          //-- Determinar el Rótulo de la Ventana
          this.ltVML11_Rotulo = this.f93DescripcionReferenciaTextoDMA(det.d011, det.d014, '', '');

          //-- Detalles (Fila .1.)
          this.ltVML11_DetallesFila1A = this.f70AMD2fDMA(cab.d002) + ' (' + this.f70HMS2fHMS(det.d901) +' - ' + this.f70HMS2fHMS(det.d902) +')';
          this.ltVML11_DetallesFila1B = cab.d582;

          //-- Buscar Detalles y Mostrar Ventana Modal
          this.lp60BuscarDetallesActividad(det.d011, det.d014);
        },

        //----------------------------------------------------------------------
        //-- Antes de OCULTAR la Leyenda .11.
        //----------------------------------------------------------------------
        lp99OcultandoLeyenda11() {
          this.p99Log(5,1,' >>> Ocultando la Leyenda .11.');
        },

        //----------------------------------------------------------------------
        //-- Búsqueda de MOVIMIENTOS 
        //----------------------------------------------------------------------
        lp98BusquedaDetalleMovimientos() {
          //-- Cargando Datos
          this.lbCargando = true;

          //-- Log
          this.p99Log(5,1,' -- lp98BusquedaDetalleMovimientos --');

          //-- Inicializaciones
          this.laRegistrosDMA                = [];

          //-- Actualizar rango de Almaceneros
          if (this.loFiltroDesdeOperario.o001 == undefined) { 
            this.ltFiltroDesdeOperario = '';
          } else {
            this.ltFiltroDesdeOperario = this.loFiltroDesdeOperario.o001;
          }

          if ((this.loFiltroHastaOperario.o001 == undefined) || (this.loFiltroHastaOperario.o001 == '')) { 
            this.ltFiltroHastaOperario = '';
          } else {
            this.ltFiltroHastaOperario = this.loFiltroHastaOperario.o001;
          }
          if (this.ltFiltroHastaOperario == '') { this.ltFiltroHastaOperario = 'zzzzzz'; }

          //-- Concatenar Filtros a Aplicar
          let lTmpIncluirMNC = this.lSWMovimientosNoConfirmados ? 'S' : 'N';

          let ltFiltrosDMA = this.ltFiltroDesdeFecha        + '^' + this.ltFiltroHastaFecha       + '^' + 

                             this.ltFiltroDesdeArticulo     + '^' + this.ltFiltroHastaArticulo    + '^' + 
                             this.ltFiltroDesdeTGP          + '^' + this.ltFiltroHastaTGP         + '^' + 
                             this.ltFiltroDesdeFES          + '^' + this.ltFiltroHastaFES         + '^' + 
                             this.ltFiltroDesdeFCO          + '^' + this.ltFiltroHastaFCO         + '^' + 
                             this.ltFiltroDesdePRO          + '^' + this.ltFiltroHastaPRO         + '^' + 
                             this.ltFiltroDesdeFAB          + '^' + this.ltFiltroHastaFAB         + '^' + 
                             this.ltFiltroDesdeSCC          + '^' + this.ltFiltroHastaSCC         + '^' + 

                             '' + '^' + '' + '^' +

                             '' + '^' + '' + '^' +
                             '' + '^' + '' + '^' +

                             this.ltFiltroDesdeOperario     + '^' + this.ltFiltroHastaOperario    + '^' + 
                             this.ltFiltroDesdeDispositivo  + '^' + this.ltFiltroHastaDispositivo + '^' +

                             '' + '^' + '' + '^' +
                             this.ltDesdeHora               + '^' + this.ltHastaHora              + '^' +

                             this.ltFiltroTipoMovimiento    + '^' +
                             lTmpIncluirMNC                 + '^' +
                             this.ltFiltroZonaCarga         + '^';

          //-- Enviar Solicitud de Borrado al Servidor
          var URL = global.lnkServerAppURL + "&s="+this.$store.state.strIDSesion+"&u="+this.$store.state.strUsuario+"&d="+this.$store.state.strDistribuidor+"&a="+this.$store.state.strAlmacen+"&t=200&m=512&x=" + encodeURIComponent(ltFiltrosDMA);
          //this.p99Log(5,1,' (lp98BusquedaDetalleMovimientos) URL = '+URL);

          //-- Contactar con el Servidor del Distribuidor
          axios.get(URL, {responseEncoding: 'utf-8'})                         
          .then(respuesta => {

              //-- Extraer la respuesta recibida
              var datos = respuesta.data;

              //-- Determinar si ha habido EXITO ó ERROR
              var flg = global.lnkGetDataError;
              if (datos.flg) {  flg = datos.flg; }

              //-- Actualizar Resultados
              this.p99Log(1,1,'-- Respuesta (200512) --');
              this.p99Log(1,2,datos);

              if (flg == global.lnkGetDataOk)  {
                  this.p99Log(1,2,' ¡¡ EXITO !!');

                  //-- Actualizar Relación de Registros
                  this.laRegistrosDMA = datos.t200m512;

                  //-- Ordenar Resultados
                  this.lp60OrdenarResultados();

                  //-- Fin de la Carga
                  this.lbCargando = false;

              } else {
                  //-- Posible mensaje de error
                  if (datos.msg) { this.p99AnuncioIA1('2','7','ERROR', datos.msg,-1); } else { this.p99AnuncioIA1('2','7','ERROR', respuesta.data,-1); }

                  //-- Fin de la Carga
                  this.lbCargando = false;
              }

          })
          .catch(error => {
              //-- Mostrar el Mensaje de Error oportuno
              this.p99Log(1,1,'** ERROR **');
              this.p99Log(1,1,error);

              //-- Mensaje al Operador
              this.p99AnuncioIA1('2','7','ERROR', error,-1);

              //-- Fin de la Carga
              this.lbCargando = false;

          });

        },

        //----------------------------------------------------------------------
        //-- Indicador de Columna y Orden aplicado
        //----------------------------------------------------------------------
        lf91ColumnaOrden(indice) {

          //-- Inicializar Resultado
          let textoHTML = '';

          //-- Aplicar Texto HTML ÚNICAMENTE sobre la columna por la cual está siendo ordenado el resultado
          if (indice == this.lnColumnaOrdenCabecera) {
            if (this.lbOrdenAscendenteCabecera) {
              textoHTML = '&nbsp;&nbsp;<i class="fa-solid fa-sort-up"/>';
            } else {
              textoHTML = '&nbsp;&nbsp;<i class="fa-solid fa-sort-down"/>';
            }
          }

          //-- Retornar Resultado
          return textoHTML;
          
        },

        //---------------------------------------------------------------------------------
        //-- Rótulo a Mostrar en el Tag (y la Lista de Operarios)
        //---------------------------------------------------------------------------------
        lf91DescripcionDesdeOperario ({ o001, o002 }) {
          //-- Casos Específicos
          if (o001 == undefined) { return 'Almacenero Inicial'; }

          //-- Resto de Casos
          return o001 + '-'+ o002;
        },

        lf91DescripcionHastaOperario ({ o001, o002 }) {
          //-- Casos Específicos
          if (o001 == undefined) { return 'Almacenero Final'; }

          //-- Resto de Casos
          return o001 + '-'+ o002;
        },


        //----------------------------------------------------------------------
        //-- Maquetación de la FECHA DE CADUCIDAD
        //----------------------------------------------------------------------
        lf91FechaCaducidad(fecha) {

          //-- Valor "vacío" (cuando NO se recibe valor alguno)
          if ((fecha == '') || (fecha == '00000000')) {
            return '';
          }

          //-- Fecha Maquetada
          return this.f70AMD2fDMA(fecha);

        },

        //----------------------------------------------------------------------
        //-- Indicador de Columna y Orden aplicado
        //----------------------------------------------------------------------
        lf92ColumnaOrden(indice) {

          //-- Inicializar Resultado
          let textoHTML = '';

          //-- Aplicar Texto HTML ÚNICAMENTE sobre la columna por la cual está siendo ordenado el resultado
          if (indice == this.lnColumnaOrdenDetalle) {
            if (this.lbOrdenAscendenteDetalle) {
              textoHTML = '&nbsp;&nbsp;<i class="fa-solid fa-sort-up"/>';
            } else {
              textoHTML = '&nbsp;&nbsp;<i class="fa-solid fa-sort-down"/>';
            }
          }

          //-- Retornar Resultado
          return textoHTML;

        },


        //----------------------------------------------------------------------
        //-- Exportar a Excel el resultado mostrado en la Tabla
        //----------------------------------------------------------------------
        lp98ExportXLS() {
          const ws = XLSX.utils.table_to_sheet(this.$refs.Tabla512);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, 'Actividad_Almacen_'+this.ltFiltroDesdeOperario+'_'+this.ltFiltroHastaOperario);
          XLSX.writeFile(wb, 'iAlmacen-512.xlsx');
        }        



    },
  
    /**************************************************************/
    /****************       Filtros / Formatos     ****************/
    /**************************************************************/
    filters: {
        //..............................................
        //--- Filtro para dar FORMATO a las FECHAS
        //..............................................
        lf60FiltroFecha: function (date) {

            let dw = date.toLocaleDateString('es-ES', { weekday: 'long' });
            dw = dw.charAt(0).toUpperCase() + dw.slice(1);

            //-- Fecha
            const d = date.getDate();
            const m = date.getMonth() + 1;
            //const y = date.getFullYear();
            const y = date.getFullYear();

            //-- Hora
            //const hora = date.getHours();
            //const minutos = date.getMinutes();

            //return dw + ' ' + (d < 10 ? '0' : '') + d + '-' + (m < 10 ? '0' : '') + m + '-' + y + ' ' + (hora < 10 ? '0' : '') + hora + ':' + (minutos < 10 ? '0' : '') + minutos;
            return dw + ' ' + (d < 10 ? '0' : '') + d + '-' + (m < 10 ? '0' : '') + m + '-' + y;
        }
    },


    //----------------------------
    //-- Datos Calculados
    //----------------------------
    computed: {
  
    },
  
    //-------------------------------
    //-- Una vez mostrada la vista
    //-------------------------------
    mounted() {
        //-- Actualizar Módulo "Vivo"
        this.$store.commit('p10StrModuleName', 'SVC51200');
  
        //-- Actualizar BreadCrumb 
        this.$store.commit('p10StrMenuBC', [
          {
            text: "5.1.2 Consulta Actividad Almacén",
            active: true
          }
       ]);

       //-- Replicar Desde/Hasta Fecha sobre los campos (texto) equivalentes
       this.lp30RangoFechasDetalleMovimientos();
    }
  
  }
  </script>
  

  <style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

  
<style lang="scss">

  //........................................................................
  //-- Atributos para la Tabla de Resultados
  //........................................................................
  .tableFixHead           { overflow: auto; height: 74vh;           }
  .tableFixHead thead th  { position: sticky; top: 0; z-index: 1;   }

  table                   { border-collapse: collapse; width: 100%; }

  tr:nth-child(even)      { background-color: $bc8;                 }
  th, td                  { padding: 8px 16px;                      }
  th                      { background-color : $bc2;                }

  .detail-row{
    background-color: $tc1;
  }  

  //-----------------------------
  //-- MultiSelect
  //-----------------------------

  .multiselect {
    min-height: unset;
  }

  .multiselect__select {
    min-height: unset;
    margin-top: -20px;
  }

  // fix multiselect weird height when using a placeholder
  .multiselect__single {
    margin-top: 5px;
    height: 10px !important;
    min-height: 0px !important;
    line-height: 0px !important;
  }

  .multiselect__placeholder {
    display: inline-block !important;
    margin-bottom: 0px !important;
    padding-top: 0px !important;
    height: 12px !important;
  }
  
  // error class on multiselect
  .multiselect.invalid .multiselect__tags {
    border: 1px solid #f86c6b !important;
  }
  
  //-- Las opciones seleccionadas
  .multiselect__option--selected {
      background: #f3f3f3;
      color: #fd8b0d;
      font-weight: bold;
  }
  
  // override default multiselect styles
  .multiselect__option--highlight {
    background: #595cde !important;
  }
  
  .multiselect__option--highlight:after {
    background: #fd8b0d !important;
  }
  
  .multiselect__tags {
    padding: 5px !important;
    min-height:12px !important;
    height:22px;
    //min-height: 10px;
  }
  
  .multiselect__placeholder{
    margin-left: 10px;
    margin-top: 2px;
    //font-size:12px !important;
  }
  
  .multiselect__tag {
    background: #fd8b0d !important;
    border: 1px solid rgba(60, 60, 60, 0.26) !important;
    color: black !important;
    margin-bottom: 0px !important;
    margin-right: 5px !important;
  }
  
  .multiselect__tag-icon:after {
    color: rgba(60, 60, 60, 0.5) !important;
  }
  
  .multiselect__tag-icon:focus,
  .multiselect__tag-icon:hover {
    background: #fd8b0d !important;
  }
  
  .multiselect__tag-icon:focus:after,
  .multiselect__tag-icon:hover:after {
    color: red !important;
  }

  

</style>
  